import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import chevronDownIcon from './images/chevron-down.svg';

const Item = ({ imageSrc, imageAlt, title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => setIsOpen((prevState) => !prevState);

  return (
    <S.Item>
      <S.Header onClick={handleClick}>
        <S.HeaderInfo>
          <S.Image src={imageSrc} alt={imageAlt} />
          <S.Title>{title}</S.Title>
        </S.HeaderInfo>
        <S.HeaderButton type="button" aria-label={title} />
        <S.ChevronIcon
          src={chevronDownIcon}
          alt="Seta para baixo que se altera para cima no clique"
          $isOpen={isOpen}
        />
      </S.Header>
      <S.Content $isOpen={isOpen}>
        <S.ContentContainer>
          <S.ContentTopTip />
          {children}
        </S.ContentContainer>
      </S.Content>
    </S.Item>
  );
};

const transitionDuration = '0.5s';
const transitionTimingFunction = 'cubic-bezier(0.52, 0.16, 0.24, 1)';

const S = {
  Item: styled.div`
    width: 100%;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      width: 324px;
    }
  `,

  Header: styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      justify-content: center;
    }
  `,

  HeaderButton: styled.button`
    position: absolute;
    width: 100%;
    height: 100%;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      display: none;
    }
  `,

  HeaderInfo: styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
  `,

  Image: styled.img`
    margin-right: 16px;
    width: 60px;
    height: 48px;
  `,

  Title: styled.h3`
    font-size: 16px;
    line-height: 19.2px;
    color: var(--color-text-title, #37474f);
    text-transform: uppercase;
  `,

  ChevronIcon: styled.img`
    width: 12px;
    height: 7.41px;
    transition: transform ${transitionDuration} ${transitionTimingFunction};

    ${({ $isOpen }) =>
      $isOpen &&
      css`
        transform: rotate(-180deg);
      `}

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      display: none;
    }
  `,

  Content: styled.div`
    max-height: 200px;
    overflow-y: hidden;
    transition: max-height ${transitionDuration} ${transitionTimingFunction},
      opacity ${transitionDuration} ${transitionTimingFunction};

    ${({ $isOpen }) =>
      !$isOpen &&
      css`
        max-height: 0;
        opacity: 0;
      `}

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      max-height: initial;
      overflow-y: auto;
      opacity: 1;
      transition: initial;
    }
  `,

  ContentContainer: styled.div`
    margin-top: 24px;
    padding: 24px;
    position: relative;
    background-color: #f5f5f5;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      margin-top: 36px;
    }

    & > p {
      font-size: 16px;
      line-height: 22px;
    }
  `,

  ContentTopTip: styled.div`
    width: 20px;
    height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #f5f5f5;
    transform: translate(100%, -50%) rotate(45deg);

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
    }
  `,
};

Item.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  imageAlt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Item;
