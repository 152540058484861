import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import quoteIcon from './images/quote.svg';

const TestimonialCard = ({ image, clientName, companyName, testimonial }) => (
  <S.TestimonialCard>
    <S.Testimonial>
      <S.QuoteIcon src={quoteIcon} alt="Aspas" />
      <span>&quot;</span>
      {testimonial}
      <span>&quot;</span>
    </S.Testimonial>

    <S.ClientContainer>
      <S.Image src={image} alt={clientName} />

      <div>
        <S.ClientName>{clientName}</S.ClientName>
        <S.CompanyName>{companyName}</S.CompanyName>
      </div>
    </S.ClientContainer>
  </S.TestimonialCard>
);

const S = {
  TestimonialCard: styled.div`
    padding: 24px;
    padding-bottom: 32px;
    height: 237px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #ffffff;
    border: 1px solid var(--color-tertiary, #c5cae9);
    user-select: none;
    -webkit-user-drag: none;
    transition: var(--hover-opacity-transition, opacity 0.25s ease-in-out);

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      padding: 32px 57px 32px 89px;
      height: 283px;
    }
    @media (min-width: 860px) {
      height: 237px;
    }

    @media (min-width: 940px) {
      height: 237px;
    }
  `,

  Testimonial: styled.p`
    font-size: 15px;
    line-height: 18px;
    font-style: italic;
    color: var(--color-text, #7f7f7f);

    @media (min-width: ${({ theme }) => theme.breakpoints.xs}) {
      font-size: 16px;
      line-height: 19.2px;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      position: relative;

      & > span:first-of-type {
        display: none;
      }
    }
  `,

  QuoteIcon: styled.img`
    display: none;
    width: 28.86px;
    height: 20.61px;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      display: block;
      position: absolute;
      top: 0;
      left: -49px;
    }
  `,

  ClientContainer: styled.div`
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  `,

  Image: styled.img`
    margin-right: 16px;
    width: 45px;
    height: 45px;
    border-radius: 100%;
    -webkit-user-drag: none;
  `,

  ClientName: styled.h3`
    font-size: 16px;
    line-height: 16px;
    font-weight: var(--font-weight-semi-bold, 600);
    color: var(--color-text-title, #37474f);
  `,

  CompanyName: styled.p`
    font-size: 10px;
    line-height: 16px;
    color: var(--color-text-title, #37474f);
  `,
};

TestimonialCard.propTypes = {
  image: PropTypes.string.isRequired,
  clientName: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  testimonial: PropTypes.string.isRequired,
};

export default TestimonialCard;
