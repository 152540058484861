import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

import ProjectCard from '../../../common/ProjectCard/ProjectCard';

const RecentProjects = () => {
  const { cockpit: portfolio } = useStaticQuery(
    graphql`
      query {
        cockpit {
          collection(
            name: "portfolio"
            filter: { published: true }
            sort: { _created: -1 }
            limit: 4
          )
        }
      }
    `,
  );

  return (
    <S.ScrollableContainer>
      <S.RecentProjects>
        {portfolio.collection.map((project) => (
          <ProjectCard
            key={project._id}
            image={`https://admin.gowebit.com.br/${project.thumbnail.path}`}
            name={project.name}
            category={project.category}
            link={project.url}
            mobiWidth="247px"
            mobiHeight="258px"
          />
        ))}
      </S.RecentProjects>
    </S.ScrollableContainer>
  );
};

const S = {
  ScrollableContainer: styled.div`
    display: flex;
    overflow-x: auto;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      margin: 0 auto;
      max-width: var(--grid-width, 1100px);
    }
  `,

  RecentProjects: styled.div`
    padding: var(--grid-padding, 0 32px);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    & > *:not(:last-of-type) {
      margin-right: 16px;
    }
  `,
};

export default RecentProjects;
