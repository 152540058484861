import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import backgroundImage from './images/header-background.svg';

const Header = () => (
  <S.Header>
    <S.HeaderContainer>
      <S.InfoContent>
        <S.Headline>tecnologia ao seu alcance</S.Headline>

        <S.Description>
          Seja um site, aplicativo ou sistema, a Gowebit está aqui por você.
        </S.Description>

        <S.StyledLink to="/contact">contate-nos</S.StyledLink>
      </S.InfoContent>

      <S.BackgroundImage
        src={backgroundImage}
        alt="Representação de alguns elementos utilizados na construção de um website"
      />
    </S.HeaderContainer>
  </S.Header>
);

const S = {
  Header: styled.header`
    padding-bottom: 32px;
    min-height: 400px;
    height: 80vh;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    background-color: var(--color-primary, #3f51b5);

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      min-height: 600px;
      align-items: center;
      justify-content: center;
    }
  `,

  HeaderContainer: styled.div`
    padding: var(--grid-padding, 0 32px);
    max-width: var(--grid-width, 1100px);

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      width: var(--grid-width, 1100px);
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  `,

  InfoContent: styled.div`
    max-width: 296px;
    color: var(--color-text-highlight, #ffffff);

    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      max-width: 413px;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      margin-right: 32px;
    }
  `,

  Headline: styled.p`
    max-width: 218px;
    font-size: 40px;
    font-weight: var(--font-weight-semi-bold, 600);
    line-height: 44px;

    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      max-width: 393px;
      font-size: 56px;
      line-height: 64px;
    }
  `,

  Description: styled.h1`
    margin-top: 16px;
    max-width: 290px;
    font-size: 16px;
    line-height: 19.2px;
  `,

  StyledLink: styled(Link)`
    margin-top: 24px;
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: var(--font-weight-semi-bold, 600);
    line-height: 12px;
    letter-spacing: 0.48px;
    color: var(--color-primary, #3f51b5);
    text-transform: uppercase;
    background-color: var(--color-text-highlight, #ffffff);
    transition: var(--hover-opacity-transition, opacity 0.25s ease-in-out);

    &:hover {
      opacity: var(--hover-opacity, 0.8);
    }
  `,

  BackgroundImage: styled.img`
    display: none;

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      display: block;
      width: 587.34px;
      height: 488.25px;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.xxl}) {
      position: absolute;
      right: -115px;
    }
  `,
};

export default Header;
