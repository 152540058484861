import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import backgroundImage from './images/contact-background.svg';

const Contact = () => (
  <S.Contact>
    <S.Container>
      <S.BackgroundImage
        src={backgroundImage}
        alt="Representação de alguns elementos utilizados na construção de um website"
      />
      <S.InfoContainer>
        <S.Title>vamos construir seu projeto juntos?</S.Title>

        <S.Description>
          Conte com a Gowebit para tirar suas dúvidas e ideias do papel
        </S.Description>

        <S.StyledLink to="/contact">fale conosco</S.StyledLink>
      </S.InfoContainer>
    </S.Container>
  </S.Contact>
);

const S = {
  Contact: styled.section`
    margin-top: 48px;
    padding: var(--grid-padding, 0 32px);
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: var(--color-secondary, #37474f);

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      margin-top: 72px;
      padding-top: 65px;
      padding-bottom: 65px;
    }
  `,

  Container: styled.div`
    margin: 0 auto;
    max-width: var(--grid-width, 1100px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      flex-direction: row;
      justify-content: space-evenly;
    }
  `,

  BackgroundImage: styled.img`
    width: 242.55px;
    height: 184.3px;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      margin-right: 32px;
      width: 356px;
      height: 270.5px;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      margin-right: 0;
    }
  `,

  InfoContainer: styled.div`
    margin-top: 24px;
    max-width: 296px;
    color: var(--color-text-highlight, #ffffff);

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      margin-top: 0;
      max-width: 386px;
    }
  `,

  Title: styled.h2`
    max-width: 254px;
    font-size: 26px;
    font-weight: var(--font-weight-semi-bold, 600);
    line-height: 29.9px;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      max-width: 386px;
      font-size: 40px;
      line-height: 44px;
    }
  `,

  Description: styled.p`
    margin-top: 8px;
    max-width: 297px;
    font-size: 15px;
    font-weight: var(--font-weight-light, 300);
    line-height: 18px;
    color: #f5f5f5;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      margin-top: 16px;
    }
  `,

  StyledLink: styled(Link)`
    margin-top: 16px;
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: var(--font-weight-semi-bold, 600);
    line-height: 12px;
    letter-spacing: 0.48px;
    color: var(--color-secondary, #37474f);
    text-transform: uppercase;
    background-color: var(--color-text-highlight, #ffffff);
    transition: var(--hover-opacity-transition, opacity 0.25s ease-in-out);

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      margin-top: 24px;
      width: 324px;
    }

    &:hover {
      opacity: var(--hover-opacity, 0.8);
    }
  `,
};

export default Contact;
