import React from 'react';
import styled from 'styled-components';

import Item from './Item/Item';

import websiteImage from './images/website.svg';
import systemImage from './images/system.svg';
import appImage from './images/app.svg';

const WhatWeDo = () => (
  <S.WhatWeDo>
    <Item
      imageSrc={websiteImage}
      imageAlt="Representação de um site em uma tela de computador"
      title="sites"
    >
      <p>
        Desenvolvemos sites institucionais, e-commerces e blogs que se adaptam
        em qualquer dispositivo e tela.
      </p>
    </Item>

    <S.HR />

    <Item
      imageSrc={systemImage}
      imageAlt="Representação de um sistema web em uma tela de computador"
      title="sistemas web"
    >
      <p>
        Aplicações online ou local completamente customizadas de acordo com suas
        necessidades e preferências.
      </p>
    </Item>

    <S.HR />

    <Item
      imageSrc={appImage}
      imageAlt="Dois dispositivos móveis representando aplicativos em suas telas"
      title="aplicativos"
    >
      <p>
        O grande público do comércio digital está nos dispositivos móveis hoje.
        Estamos prontos para tirar seu app do papel.
      </p>
    </Item>
  </S.WhatWeDo>
);

const S = {
  WhatWeDo: styled.div`
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      & > *:not(:last-of-type) {
        margin-right: 32px;
      }
    }
  `,

  HR: styled.hr`
    margin-top: 16px;
    margin-bottom: 16px;
    border-top: 1px solid #cccccc;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      display: none;
    }
  `,
};

export default WhatWeDo;
