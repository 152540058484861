import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';

import svgAddCircle from './images/add-circle.svg';

const Section = ({
  title,
  linkToSeeAll,
  mobiMarginTop,
  deskMarginTop,
  isHalfWidth,
  hasContentOutsideGrid,
  children,
}) => (
  <S.Section $isHalfWidth={isHalfWidth}>
    <S.Header>
      <S.Title>{title}</S.Title>
      {linkToSeeAll && (
        <S.SeeAll to={linkToSeeAll}>
          <img src={svgAddCircle} alt="Sinal de mais, dentro de um círculo" />
          <span>ver todos</span>
        </S.SeeAll>
      )}
    </S.Header>

    <S.Content
      $mobiMarginTop={mobiMarginTop}
      $deskMarginTop={deskMarginTop}
      $hasContentOutsideGrid={hasContentOutsideGrid}
    >
      {children}
    </S.Content>
  </S.Section>
);

const S = {
  Section: styled.section`
    margin-top: 48px;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      margin-top: 56px;

      ${({ $isHalfWidth }) =>
        $isHalfWidth &&
        css`
          width: 50%;
          display: inline-block;
        `}
    }
  `,

  Header: styled.div`
    margin: 0 auto;
    padding: var(--grid-padding, 0 32px);
    max-width: var(--grid-width, 1100px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
  `,

  Title: styled.h2`
    font-size: 16px;
    line-height: 18.4px;
    font-weight: var(--font-weight-semi-bold, 600);
    color: var(--color-text-title, #37474f);

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: 24px;
      line-height: 29.9px;
    }
  `,

  SeeAll: styled(Link)`
    margin-left: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    line-height: 11.5px;
    color: var(--color-primary, #3f51b5);
    font-weight: var(--font-weight-bold, 700);
    text-transform: uppercase;
    transition: var(--hover-opacity-transition, opacity 0.25s ease-in-out);

    &:hover {
      opacity: var(--hover-opacity, 0.8);
    }

    img {
      margin-right: 6px;
      width: 10px;
      height: 10px;
    }
  `,

  Content: styled.div`
    margin: 0 auto;
    margin-top: ${({ $mobiMarginTop }) => $mobiMarginTop || '16px'};
    padding: var(--grid-padding, 0 32px);
    max-width: var(--grid-width, 1100px);

    ${({ $hasContentOutsideGrid }) =>
      $hasContentOutsideGrid &&
      css`
        padding: 0;
        max-width: initial;
      `}

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      margin-top: ${({ $deskMarginTop }) => $deskMarginTop || '24px'};

      ${({ $isHalfWidth }) =>
        $isHalfWidth &&
        css`
          margin-top: ${({ $deskMarginTop }) => $deskMarginTop || '16px'};
        `}
    }
  `,
};

Section.propTypes = {
  title: PropTypes.string,
  linkToSeeAll: PropTypes.string,
  mobiMarginTop: PropTypes.string,
  deskMarginTop: PropTypes.string,
  isHalfWidth: PropTypes.bool,
  hasContentOutsideGrid: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default Section;
