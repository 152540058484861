import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import 'keen-slider/keen-slider.min.css';
import { useKeenSlider } from 'keen-slider/react';

import chevronLeftIcon from './images/chevron-left.svg';
import chevronRightIcon from './images/chevron-right.svg';

import TestimonialCard from './TestimonialCard/TestimonialCard';

const Testimonials = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const [sliderRef, slider] = useKeenSlider({
    slidesPerView: 1,
    spacing: 32,
    loop: true,
    breakpoints: {
      '(min-width: 620px)': {
        slidesPerView: 2,
      },
    },
    slideChanged: (s) => setCurrentSlide(s.details().relativeSlide),
  });

  const { cockpit: testimonials } = useStaticQuery(
    graphql`
      query {
        cockpit {
          collection(
            name: "testimonials"
            filter: { published: true }
            sort: { _created: -1 }
            limit: 5
          )
        }
      }
    `,
  );

  const handlePrevSlide = (e) => e.stopPropagation() || slider.prev();

  const handleNextSlide = (e) => e.stopPropagation() || slider.next();

  return (
    <>
      <S.Testimonials>
        <div ref={sliderRef} className="keen-slider">
          {testimonials.collection.map((testimonial) => (
            <div key={testimonial._id} className="keen-slider__slide">
              <TestimonialCard
                image={`https://admin.gowebit.com.br/${testimonial.picture.path}`}
                clientName={testimonial.clientName}
                companyName={testimonial.companyName}
                testimonial={testimonial.testimonial}
              />
            </div>
          ))}
        </div>

        <S.SliderButton onClick={handlePrevSlide} $isLeft>
          <img src={chevronLeftIcon} alt="Seta para a esquerda" />
        </S.SliderButton>

        <S.SliderButton onClick={handleNextSlide} $isRight>
          <img src={chevronRightIcon} alt="Seta para a direita" />
        </S.SliderButton>
      </S.Testimonials>

      {slider && (
        <S.Bullets className="dots">
          {[...Array(slider.details().size).keys()].map((i) => (
            <S.Bullet key={`bullet-${i}}`} $isActive={currentSlide === i} />
          ))}
        </S.Bullets>
      )}
    </>
  );
};

const S = {
  Testimonials: styled.div`
    margin: 0 auto;
    padding: var(--grid-padding, 0 32px);
    max-width: var(--grid-width, 1100px);
    position: relative;
  `,

  Bullets: styled.div`
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    & > *:not(:last-of-type) {
      margin-right: 10px;
    }
  `,

  SliderButton: styled.button`
    display: none;
    width: 48px;
    height: 48px;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    border-radius: 100%;
    transition: background-color 0.25s ease-in-out;

    ${({ $isLeft }) =>
      $isLeft &&
      css`
        left: 32px;
        transform: translate(8px, -50%);
      `}

    ${({ $isRight }) =>
      $isRight &&
      css`
        right: 32px;
        transform: translate(-8px, -50%);
      `}

    &:hover {
      background-color: #f5f5f5;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      display: flex;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
      ${({ $isLeft }) =>
        $isLeft &&
        css`
          transform: translate(calc(-100% - 24px), -50%);
        `}

      ${({ $isRight }) =>
        $isRight &&
        css`
          transform: translate(calc(100% + 24px), -50%);
        `}
    }

    & > img {
      width: 24px;
      height: 24px;
    }
  `,

  Bullet: styled.div`
    width: 6px;
    height: 6px;
    border-radius: 100%;
    background-color: ${({ $isActive }) =>
      $isActive
        ? 'var(--color-primary, #3f51b5)'
        : 'var(--color-tertiary, #c5cae9)'};
  `,
};

export default Testimonials;
