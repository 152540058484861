import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ProjectCard = ({
  image,
  name,
  category,
  link,
  mobiWidth,
  mobiHeight,
  deskWidth,
  deskHeight,
}) => (
  <S.Anchor href={link} target="_blank" rel="noopener noreferrer">
    <S.ProjectCard
      $mobiWidth={mobiWidth}
      $mobiHeight={mobiHeight}
      $deskWidth={deskWidth}
      $deskHeight={deskHeight}
    >
      <S.ImageContainer>
        <img src={image} alt={name} />
      </S.ImageContainer>

      <S.InfoContainer>
        <S.Name>{name}</S.Name>
        <S.Category>{category}</S.Category>
      </S.InfoContainer>
    </S.ProjectCard>
  </S.Anchor>
);

const S = {
  Anchor: styled.a`
    -webkit-user-drag: none;
  `,

  ProjectCard: styled.div`
    width: ${({ $mobiWidth }) => $mobiWidth || '100%'};
    height: ${({ $mobiHeight }) => $mobiHeight || '275px'};
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;
    user-select: none;
    transition: var(--hover-opacity-transition, opacity 0.25s ease-in-out);

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      width: ${({ $deskWidth, $mobiWidth }) =>
        $deskWidth || $mobiWidth || '100%'};
      height: ${({ $deskHeight, $mobiHeight }) =>
        $deskHeight || $mobiHeight || '275px'};
    }

    &:hover {
      opacity: var(--hover-opacity, 0.8);
    }
  `,

  ImageContainer: styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-primary, #3f51b5);
    overflow: hidden;

    img {
      width: 300px;
      height: 180px;
      -webkit-user-drag: none;
    }
  `,

  InfoContainer: styled.div`
    padding: 16px;
    height: 78px;
  `,

  Name: styled.h3`
    font-size: 16px;
    line-height: 24px;
    color: var(--color-primary, #3f51b5);
  `,

  Category: styled.p`
    font-size: 10px;
    line-height: 14px;
    color: var(--color-text, #7f7f7f);
    text-transform: capitalize;
  `,
};

ProjectCard.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  mobiWidth: PropTypes.string,
  mobiHeight: PropTypes.string,
  deskWidth: PropTypes.string,
  deskHeight: PropTypes.string,
};

export default ProjectCard;
