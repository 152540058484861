import React from 'react';

import Layout from '../../common/Layout/Layout';
import Seo from '../../common/Seo/Seo';
import Header from './Header/Header';
import Section from './Section/Section';
import WhatWeDo from '../../common/WhatWeDo/WhatWeDo';
import RecentProjects from './RecentProjects/RecentProjects';
import Testimonials from './Testimonials/Testimonials';
import Contact from './Contact/Contact';

const IndexPage = () => (
  <Layout hasContentBehindNavbar isNavbarLogoWhite>
    <Seo title="Sites, sistemas e aplicativos" />
    <Header />

    <Section title="o que fazemos" mobiMarginTop="24px" deskMarginTop="40px">
      <WhatWeDo />
    </Section>

    <Section title="projetos recentes" hasContentOutsideGrid>
      <RecentProjects />
    </Section>

    <Section title="o que dizem nossos clientes" hasContentOutsideGrid>
      <Testimonials />
    </Section>

    <Contact />
  </Layout>
);

export default IndexPage;
